import PropTypes from 'prop-types'
import styled, {css} from 'styled-components'
import media from '../utils/media'
import ContentContainer from './ContentContainer'


const StyledBackground = styled.div`
  &::before {
    position: fixed;
    z-index: -1;
    background-image: url(${({mobileBackground}) => mobileBackground});
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    min-width: 100vw;
    min-height: 100vh;
    content: '';
    ${media.up('mobile')(css`
      background-image: url(${({desktopBackground}) => desktopBackground});
      background-position: center 10%;
    `)}
  }
`

const StyledOverlayContainer = styled.div`
  padding: 15rem 0 0;
`

const StyledOverlay = styled.div`
  background: linear-gradient(${({theme}) => theme.overlays.gradient});
  padding-top: calc((100vh * 1) / 3);
  padding-bottom: 5rem;
  ${media.down('mobile')(css`
    padding-bottom: 14rem;
  `)}
`

const PaddedBackground = ({desktopBackground, mobileBackground, children, ...props}) => {
  return (
    <StyledBackground
        desktopBackground={desktopBackground}
        mobileBackground={mobileBackground}
        {...props}
    >
      <StyledOverlayContainer>
        <StyledOverlay>
          <ContentContainer>
            {children}
          </ContentContainer>
        </StyledOverlay>
      </StyledOverlayContainer>
    </StyledBackground>
  )
}

PaddedBackground.propTypes = {
  desktopBackground: PropTypes.string.isRequired,
  mobileBackground: PropTypes.string.isRequired,
  children: PropTypes.node,
}

export default PaddedBackground
